import { UserOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Button, Dropdown, MenuProps, Space, Typography } from 'antd'
import React, { useMemo } from 'react'

import { RightContentDocument } from '../../generated/graphql'

type Props = {
  user: { email: string }
}

export const RightContent: React.FC<Props> = ({ user }) => {
  const { signOut } = useAuthenticator()
  const { data } = useQuery(RightContentDocument)
  const ticketProvider = data?.ticketProvider

  const menu: MenuProps = useMemo(
    () => ({
      items: [
        {
          key: '1',
          label: 'ログアウト',
          onClick: signOut,
        },
      ],
    }),
    [signOut]
  )

  return (
    <Space>
      <Typography>
        {user.email}｜{ticketProvider?.name || '...'}
      </Typography>
      <Dropdown menu={menu}>
        <Button shape="circle" icon={<UserOutlined />} />
      </Dropdown>
    </Space>
  )
}
