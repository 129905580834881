import '../styles/amplify.css'
import '../styles/global.css'
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css'

import { DashboardOutlined } from '@ant-design/icons'
import { Route } from '@ant-design/pro-layout/lib/typing'
import { ApolloProvider } from '@apollo/client'
import {
  Authenticator,
  translations,
  useAuthenticator,
} from '@aws-amplify/ui-react'
import { ConfigProvider } from 'antd'
import locale from 'antd/locale/ja_JP'
import { Amplify } from 'aws-amplify'
import { I18n } from 'aws-amplify/utils'
import { AppProps } from 'next/app'
import Router from 'next/router'
import React, { useEffect, useState } from 'react'

import { useApollo } from '../apollo/apolloClient'
import { BasicLayout } from '../components/layouts'
import ErrorTracker from '../utils/ErrorTracker'

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID ?? '',
      userPoolClientId: process.env.NEXT_PUBLIC_USER_POOL_WEB_CLIENT_ID ?? '',
    },
  },
})

I18n.putVocabulariesForLanguage('ja', translations.ja)
I18n.setLanguage('ja')

const route: Route = {
  routes: [
    {
      path: '/reports/usage',
      name: 'チケット使用集計期間一覧',
      icon: <DashboardOutlined />,
      routes: [
        {
          path: '/reports/usage/detail',
          name: 'チケット使用明細',
          hideInMenu: true,
        },
      ],
    },
  ],
}

const useLoadingState = () => {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    Router.events.on('routeChangeStart', () => setLoading(true))
    Router.events.on('routeChangeComplete', () => setLoading(false))
    Router.events.on('routeChangeError', () => setLoading(false))
  }, [])

  return { loading }
}

const App = (props: AppProps) => (
  <Authenticator.Provider>
    <MyApp {...props} />
  </Authenticator.Provider>
)

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  const apolloClient = useApollo()
  const { user: authUser, route: authRoute } = useAuthenticator()
  const user = { email: authUser?.signInDetails?.loginId ?? '' }
  ErrorTracker.setUser(user || null)

  const { loading } = useLoadingState()

  return authRoute === 'authenticated' && user ? (
    <ApolloProvider client={apolloClient}>
      <ConfigProvider locale={locale}>
        <BasicLayout user={user} route={route} loading={loading}>
          <Component {...pageProps} />
        </BasicLayout>
      </ConfigProvider>
    </ApolloProvider>
  ) : (
    <Authenticator loginMechanisms={['email']} hideSignUp />
  )
}

export default App
