import * as Sentry from '@sentry/nextjs'

const trackError = (error: Error) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error(error)
    return
  }

  Sentry.captureException(error)
}

const setUser = (user: Sentry.User | null) => {
  if (user) {
    Sentry.setUser({ user })
  } else {
    // https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/identify-user/
    Sentry.configureScope((scope) => scope.setUser(null))
  }
}

export default {
  trackError,
  setUser,
}
