import { MenuDataItem, ProLayoutProps } from '@ant-design/pro-layout'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useCallback } from 'react'

import { Footer } from './Footer'
import { RightContent } from './RightContent'

const AntdProLayout = dynamic(
  async () => (await import('@ant-design/pro-layout')).ProLayout,
  { ssr: false }
)

const Logo: React.ReactNode = (
  <Image src="/logo.svg" width={32} height={32} alt="logo" />
)

type Props = Required<Pick<ProLayoutProps, 'route' | 'loading'>> & {
  children: React.ReactNode
  user: { email: string }
}

export const BasicLayout: React.FC<Props> = ({
  user,
  route,
  loading,
  children,
}) => {
  const router = useRouter()
  const handleMenuHeaderClick = useCallback(
    () => router.push('/reports/usage'),
    [router]
  )
  const handleMenuItemRender = useCallback(
    (options: MenuDataItem, element: React.ReactNode) => (
      <Link href={options.path!}>{element}</Link>
    ),
    []
  )
  const headerContentRender = useCallback(
    () => (
      <div style={{ float: 'right' }}>
        <RightContent user={user} />
      </div>
    ),
    [user]
  )

  return (
    <AntdProLayout
      title="RYDE PASS"
      logo={Logo}
      route={route}
      loading={loading}
      navTheme="light"
      layout="mix"
      fixSiderbar
      onMenuHeaderClick={handleMenuHeaderClick}
      menuItemRender={handleMenuItemRender}
      headerContentRender={headerContentRender}
      footerRender={Footer}
      style={{ minHeight: '100vh' }}
    >
      {children}
    </AntdProLayout>
  )
}
