import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { fetchAuthSession } from 'aws-amplify/auth'
import { useMemo } from 'react'

import { API_URL } from '../config/settings'

const initializeApollo = () => {
  const httpLink = createHttpLink({
    uri: API_URL,
  })
  const authLink = setContext(async (_, { headers }) => {
    const session = await fetchAuthSession()
    const token = session.tokens?.idToken
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    }
  })

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({}),
  })
}

export const useApollo = () => useMemo(() => initializeApollo(), [])
